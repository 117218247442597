<script>
export default {
  name: "BasePollContainer",
  props: {
    /**
     * must be presentation, interactive, test
     */
    moduleType: {
      type: String,
      required: true,
    },
    testId: {
      type: [String, Number],
    },
  },
};
</script>

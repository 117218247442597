<template>
  <div
    class="poll-scores"
    :class="{
      voting: pollChain.is_voting,
      'poll-scores--one-word-cloud': oneWordCloud,
      'poll-scores--ios': $isMobileIOS,
      'poll-scores--set': pollChain.questions && pollChain.questions.length > 1,
    }"
  >
    <div class="poll-scores__wrap">
      <poll-scores-item
        v-for="poll in pollChain.questions"
        :key="poll.id"
        :poll="poll"
        :poll-chain="pollChain"
        :is-voting="!!pollChain.is_voting"
        :fill-height="!isUpdateResultsPossible"
      />
    </div>
    <test-update-results
      v-if="isUpdateResultsPossible"
      class="poll-scores__update"
      :renew-action="renewResults"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from "vuex";
import TestUpdateResults from "@/components/event/room/Tests/components/TestUpdateResults";
import { DARK_THEME, CUSTOM_THEME } from "@/constants/themes/themes-const";
import { room, polls } from "@/store/modules/store.namespaces";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { GET_COMMON_TEST_RESULTS } from "@/store/modules/polls/action-types";
import {
  RESET_INTERACTIVE_BACKGROUND,
  RESET_INTERACTIVE_THEME,
  SET_INTERACTIVE_BACKGROUND,
  SET_INTERACTIVE_THEME,
} from "@/store/modules/polls/mutation-types";
import PollScoresItem from "./PollScoresItem";

const RENEW_INTERVAL = 10000;

export default {
  name: "PollScores",
  components: {
    TestUpdateResults,
    PollScoresItem,
  },
  props: {
    isScreen: {
      type: Boolean,
      default: false,
    },
    pollChain: {
      type: Object,
      required: true,
    },
    moduleType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      renewInterval: null,
    };
  },
  computed: {
    ...mapState(polls, ["isCheatPoints"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    pollChainId() {
      return this.pollChain.id;
    },
    isShowProjectorResult() {
      return this.pollChain.show_projector_result;
    },
    isProjector() {
      return this.isShowProjectorResult && this.isScreen;
    },
    themeColorForClosed() {
      return (
        this.pollChain &&
        this.pollChain.questions &&
        this.pollChain.questions.length === 1 &&
        this.pollChain.questions[0] &&
        this.pollChain.questions[0].props &&
        this.pollChain.questions[0].props.theme_color &&
        this.pollChain.questions[0].props.theme_color.for_closed
      );
    },
    backgroundForClosed() {
      return (
        this.pollChain &&
        this.pollChain.questions &&
        this.pollChain.questions.length === 1 &&
        this.pollChain.questions[0] &&
        this.pollChain.questions[0].custom_image
      );
    },
    pollChainQuestions() {
      return this.pollChain && this.pollChain.questions;
    },
    hasClosedTypeQuestion() {
      return this.pollChainQuestions.some(
        question => question.poll_type === "closed" || question.props.poll_type === "closed",
      );
    },
    isUpdateResultsPossible() {
      return (!!this.pollChain.is_voting || this.hasClosedTypeQuestion) && !this.isProjector;
    },
    oneWordCloud() {
      return (
        this.pollChain.questions &&
        this.pollChain.questions.length === 1 &&
        this.pollChain.questions[0].props.poll_type === "word-cloud"
      );
    },
  },
  mounted() {
    if ((this.isCheatPoints || this.isShowProjectorResult) && this.isScreen) {
      this.setRenewInterval();
    }
  },
  created() {
    if (this.themeColorForClosed === DARK_THEME) {
      this.setInteractiveTheme(DARK_THEME);
    }
    if (this.themeColorForClosed === CUSTOM_THEME) {
      this.setInteractiveTheme(CUSTOM_THEME);
      this.setInteractiveBg(this.backgroundForClosed);
    }
  },
  beforeDestroy() {
    this.resetInteractiveTheme();
    this.resetInteractiveBg();
    this.clearRenewInterval();
  },
  methods: {
    ...mapMutations(polls, {
      resetInteractiveTheme: RESET_INTERACTIVE_THEME,
      resetInteractiveBg: RESET_INTERACTIVE_BACKGROUND,
      setInteractiveTheme: SET_INTERACTIVE_THEME,
      setInteractiveBg: SET_INTERACTIVE_BACKGROUND,
    }),
    ...mapActions(polls, {
      getCommonResults: GET_COMMON_TEST_RESULTS,
    }),
    async renewResults() {
      await this.getCommonResults({
        type: this.moduleType,
        payload: this.pollChainId,
      });
    },
    setRenewInterval() {
      this.renewInterval = setInterval(this.renewResults, RENEW_INTERVAL);
    },
    clearRenewInterval() {
      clearInterval(this.renewInterval);
    },
  },
};
</script>

<style scoped lang="less">
@import url(~@/styles/_mixin.less);

.poll-scores {
  width: 100%;

  &--one-word-cloud {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;

    ::v-deep {
      .poll-scores__wrap {
        flex: 1 1 auto;
        padding: 20px 10px;

        @media (min-width: 480px) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .poll-score {
        display: flex;
        flex-direction: column;
      }
      .word-cloud {
        flex: 1;
        min-height: 100%;

        .cloud {
          position: relative;
          flex: 1;
          height: initial;
        }

        .cloud-wrapper {
          position: absolute !important;
        }
      }
    }
  }

  &--set {
    width: 100%;
    max-height: 100%;
    margin: auto;
    overflow: auto;

    ::v-deep .word-cloud {
      height: 70vh;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    overflow: auto;
    .reset-scroll();
  }

  &__update {
    display: flex;
    padding: 5px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    bottom: 30px;
    text-align: center;
    width: 100%;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    background-color: rgba(255, 255, 255, 0.9);
  }

  ::v-deep .voting {
    height: 100vh;
    padding-top: 30px;
    overflow-x: hidden;

    ::v-deep .poll-score {
      margin-bottom: 0;
    }

    .word-cloud {
      height: 100%;
    }
    .renew {
      bottom: 0;

      &--ios {
        top: 44px;
        font-size: 15px;
        margin-top: 0;
        height: max-content;
      }
    }
  }
}

.renew {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: auto;
  transform: rotateX(-50%);
}

.interactive .renew {
  display: block;
}
</style>

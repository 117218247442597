<template>
  <poll-scores
    :poll-chain="pollChain"
    :is-screen="isScreen"
    :module-type="moduleType"
  ></poll-scores>
</template>

<script>
import PollScores from "@/components/common/polls/components/PollScores";
import BasePollContainer from "./components/BasePollContainer";

export default {
  name: "PollScoreContainer",
  components: {
    PollScores,
  },
  extends: BasePollContainer,
  props: {
    isScreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pollChain() {
      // todo think about getter for this and map it
      return this.$store.state.polls[this.moduleType].chainScore;
    },
  },
};
</script>

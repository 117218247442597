<template>
  <div class="renew" :class="{ 'renew-dark': isDark }">
    <transition name="fade" mode="out-in" duration="20">
      <loader v-if="loading && showPreloader" class="renew__loader" />
      <ws-button v-else text color="primary" @click="renewResults">
        <ws-icon light>redo</ws-icon>
        {{ $t("testNGames.updateResult") }}
      </ws-button>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DARK_THEME } from "@/constants/themes/themes-const";
import Loader from "@/components/common/elements/Loader";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "TestUpdateResult",
  components: {
    Loader,
    WsButton,
    WsIcon,
  },
  props: {
    renewAction: {
      type: Function,
      required: true,
    },
    showPreloader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(["interactiveTheme"]),
    isDark() {
      return this.interactiveTheme === DARK_THEME;
    },
  },
  methods: {
    async renewResults() {
      this.loading = true;
      await this.renewAction();
      setTimeout(() => {
        this.loading = false;
      }, 100);
    },
  },
};
</script>

<style scoped lang="less">
.renew {
  overflow: hidden;

  .throbber {
    width: auto;
    margin: 0;
  }

  &-dark {
    color: #fff;
  }
}
</style>

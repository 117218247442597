<template>
  <div v-if="time && available" ref="time" class="timer icon-clock">
    <div class="timer__time">{{ time }}</div>
  </div>
</template>

<script>
import EasyTimer from "easytimer";
import { mapActions } from "vuex";
import { room } from "@/store/modules/store.namespaces";
import { GET_ROOM_TIMER } from "@/store/modules/common/action-types";

const ZERO_TIME = `00:00:00`;

export default {
  props: {
    initialTimeInSeconds: {
      type: [Number, String],
      default: 0,
    },
    commonTimer: {
      type: Boolean,
      default: false,
    },
    startTimeStamp: {
      type: Number,
      default: 0,
    },
    timerPeriod: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      time: null,
      timer: null,
      serverTimer: null,
      refreshTimeCounter: 0,
    };
  },
  computed: {
    seconds() {
      return +this.initialTimeInSeconds;
    },
    available() {
      return !!this.initialTimeInSeconds;
    },
    boundSecondsUpdateHandler() {
      return this.secondsUpdateHandler.bind(this);
    },
  },
  watch: {
    seconds() {
      this.startTimer();
    },
    time(val) {
      if (!val) {
        this.$emit("time-is-out");
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.serverTimer);
    this.refreshTimeCounter = 0;
    this.timer.removeEventListener("secondsUpdated", this.boundSecondsUpdateHandler);
  },
  created() {
    this.timer = new EasyTimer();
    this.timer.addEventListener("secondsUpdated", this.boundSecondsUpdateHandler);
  },
  mounted() {
    if (this.initialTimeInSeconds) {
      this.startTimer();
    }
    if (this.commonTimer) {
      this.refreshTimer();
      setTimeout(() => {
        this.refreshTimeCounter++;
        if (this.refreshTimeCounter < 4) {
          this.refreshTimer();
        }
      }, 500);
    }
  },
  methods: {
    ...mapActions(room, {
      getRoomTimer: GET_ROOM_TIMER,
    }),
    secondsUpdateHandler() {
      const value = this.timer.getTimeValues().toString();
      if (value !== ZERO_TIME) {
        this.time = value;
        this.$emit("timer-tick", this.timer.getTotalTimeValues().seconds);
      } else {
        this.$emit("time-is-out");
        this.time = null;
      }
    },
    startTimer() {
      if (this.seconds === 0) {
        this.timer.stop();
        return;
      }
      this.timer.stop();
      this.timer.start({
        countdown: true,
        precision: "seconds",
        startValues: {
          seconds: this.remainingSeconds() || +this.initialTimeInSeconds,
        },
      });
    },
    refreshTimer() {
      this.getRoomTimer();
    },
    remainingSeconds() {
      let remainingSec = this.startTimeStamp + this.timerPeriod - Math.round(Date.now() / 1000);
      remainingSec = remainingSec < 0 ? 0 : remainingSec;
      return remainingSec;
    },
  },
};
</script>

<style lang="less" scoped>
.timer {
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    font-size: 12px;
    color: var(--base-color);
  }
}

.timer__time {
  font-family: "ProximaNova", sans-serif;
  font-size: 12px;
  color: #8f1441;
  width: auto;
  text-align: left;
  box-sizing: content-box;
  padding-left: 8px;
  margin-right: 10px;
}
</style>

<template>
  <div v-if="moduleType && pollChainId" class="poll-test-result-common">
    <div class="poll-test-result-common__head">
      <div class="poll-test-result-common__head-col-left">
        <back-button @go-back="back" />
      </div>
      <div class="poll-test-result-common__head-col-center">
        <div v-if="testRepeatIsDisabled" class="poll-test-result-common__timer">
          {{ $t("testNGames.testRepeatDisabled") }}
          <test-timer :initial-time-in-seconds="testRepeatAfterTime" @time-run-out="onTimeRunOut" />
        </div>
      </div>
      <div class="poll-test-result-common__head-col-right">
        <test-update-results :renew-action="renewResultsByUser" :show-preloader="false" />
      </div>
    </div>
    <poll-score-container
      class="poll-test-result-common__scores"
      :module-type="moduleType"
      :class="{ 'poll-test-result-common__scores--loading': loading }"
    ></poll-score-container>
    <loader v-if="loading" class="poll-test-result-common__loader"></loader>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import BackButton from "@/components/common/elements/BackButton";
import BasePollContainer from "@/components/common/polls/components/BasePollContainer";
import TestTimer from "@/components/common/polls/components/common/TestTimer";
import TestUpdateResults from "@/components/event/room/Tests/components/TestUpdateResults";
import { SET_SHOW_RESULT_AS_COMMON } from "@/store/modules/polls/mutation-types";
import { polls, room } from "@/store/modules/store.namespaces";
import Loader from "@/components/common/elements/Loader";
import { GET_COMMON_TEST_RESULTS, GET_TESTS } from "@/store/modules/polls/action-types";
import { TESTS_BY_STATUS } from "@/store/modules/polls/getter-types";
import PollScoreContainer from "./PollScoreContainer";

export default {
  name: "PollTestResultCommon",
  components: {
    PollScoreContainer,
    BackButton,
    TestTimer,
    Loader,
    TestUpdateResults,
  },
  extends: BasePollContainer,
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(room, {
      isArchiveInactiveTests: state => state.roomInfo.archive_inactive_tests,
    }),
    ...mapState(polls, ["tests"]),
    ...mapGetters(polls, {
      tests: TESTS_BY_STATUS,
    }),
    /**
     * Тест, по которому необходимо получить результат.
     */
    test() {
      return this.tests?.finished?.find(testItem => testItem.id === this.pollChainId);
    },
    testRepeatIsDisabled() {
      return !!(this.test && this.test.repeat_pass_locked && this.test.repeat_pass_wait);
    },
    testRepeatAfterTime() {
      return this.test.repeat_pass_wait;
    },
    pollChainId() {
      return this.moduleType && this.$store.state.polls[this.moduleType].chainScore?.id;
    },
  },
  async created() {
    if (!this.checkHasTestsInState()) {
      await this.getAllTests();
    }
    if (!this.pollChainId) {
      await this.getCommonResults({
        type: this.moduleType,
        payload: this.testId,
      });
    }
  },
  beforeDestroy() {
    if (this.moduleType) {
      this.setShowResultAsCommon({
        type: this.moduleType,
        payload: false,
      });
    }
  },
  methods: {
    ...mapActions(polls, {
      getCommonResults: GET_COMMON_TEST_RESULTS,
      getAllTests: GET_TESTS,
    }),
    ...mapMutations(polls, {
      setShowResultAsCommon: SET_SHOW_RESULT_AS_COMMON,
    }),
    back() {
      if (this.isArchiveInactiveTests) {
        this.$router.push({ name: "tests", query: { showFinished: "true" } });
      } else {
        this.$router.push({ name: "tests" });
      }
    },
    async renewResults() {
      await this.getCommonResults({
        type: this.moduleType,
        payload: this.pollChainId,
      });
    },
    async renewResultsByUser() {
      this.loading = true;
      await this.renewResults();
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
    onTimeRunOut() {
      this.getAllTests();
    },
    checkHasTestsInState() {
      return Object.keys(this.tests).length;
    },
  },
};
</script>

<style scoped lang="less">
@import url("~@/styles/_mixin");

.poll-test-result-common {
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  min-height: 100%;

  &__head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 0 0 auto;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);

    @media (max-width: 560px) {
      flex-wrap: wrap;
      padding: 5px;
    }

    &-col-left,
    &-col-right {
      flex: 0 0 auto;
      margin: 5px;

      @media (max-width: 560px) {
        flex-grow: 1;
      }
    }

    &-col-right {
      text-align: right;
    }

    &-col-center {
      flex: 1 1 auto;
      text-align: center;

      @media (max-width: 560px) {
        order: 1;
        flex-basis: 100%;
      }
    }
  }

  &__timer {
    color: #636363;
    font-size: 12px;
    line-height: 1;
  }

  &__btn-icon {
    display: inline-block;
    margin: 4px 4px 0 0;
    vertical-align: top;
    font-size: 15px;
  }

  &__scores {
    flex: 1 1 auto;

    &--loading {
      filter: blur(5px);
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

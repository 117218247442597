const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
export default function createAlphabeticalIndex(i) {
  let ind = i;
  let alphIndex = [];
  do {
    let currentLetterIndex = ind % 26;
    ind = Math.floor(ind / 26);
    alphIndex.unshift(currentLetterIndex);
  } while (ind >= 26);
  return alphIndex
    .map(letterIndex => {
      return alphabet[letterIndex];
    })
    .join("");
}
